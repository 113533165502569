import React from "react";
import TextCol from "../sections/landing/textCol";
const LearnMore = (props) => {
  return (
    <>
      <TextCol
        subtitle={props.subtitle}
        title={props.title}
        cta={props.cta}
        className='pt-8'
      >

          {props.children}
      </TextCol>
      <img src={props.image} className='w-full object-cover' style={{maxHeight: "750px"}}>
      </img>
    </>
  );
};

export default LearnMore;
