import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LearnMore from "../templates/learnMore";
import phone_call_1 from "../images/phone_call_1.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Dedicated Desks"
      />
      <LearnMore
        title={`Dedicated desks, a work 
        space as dedicated as you`}
        cta={`Get in touch`}
        image={phone_call_1}
      >
        <div>
          <p className="text-xl">
            Choose your dedicated desk in an opened shared office for 6 means
            opening yourself up to possibilities. Our community of like-minded
            businesses represent an array of brand maturity, from well-known
            international brands who sought a stylish Richmond presence, to
            ambitious start-ups ready to make their mark on Australia and
            beyond. Your dedicated desk is in one of the most enviable positions
            across the space: Our mezzanine. You will enjoy the “CEO view” –
            floor to ceiling glass windows overlooking one of our larger spaces
            below and offering an uninterrupted view of the inspiring Bryant and
            May building.
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            Alongside our modern minimalist aesthetic, you will discover our
            cutting-edge facilities, seamless concierge services and an
            environment designed to delight and inspire, every single day. Your
            generously sized desk and lockable cabinet form a blank canvas on
            which you can create your ideal workspace and organisation. Unlike
            other flexible workspaces you may have considered in the area, we
            choose to be unbranded. What this means is you will not find our
            logo on the door, nor highlighted within. By doing this, we allow
            you and your brand to present themselves as a single, larger entity.{" "}
          </p>
        </div>
      </LearnMore>
    </Layout>
  );
}

export default IndexPage;
