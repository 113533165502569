import React from "react";
import { Link } from "gatsby";

export default function TextCol(props) {
  return (
    <section className={`bg-white text-black relative ${props.className ? props.className : ``}`}>
      <div className="py-16 md:py-24 px-4 max-w-7xl mx-auto">
        <div className="max-w-md">
          {props.subtitle && (
            <p className="text-black text-xl uppercase">
              {props.subtitle}
            </p>
          )}
          {props.title && (
            <h2 className="text-black text-3xl md:text-4xl pt-4">
              {props.title}
            </h2>
          )}
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-16 pt-8 md:pt-12">
          {props.children}
        </div>
        {props.cta && (
          <div className="pt-14">
            <Link to="/contact">
            <p className="underline text-lg pt-2">{props.cta}</p>

            </Link>
          </div>
        )}
      </div>
    </section>
  );
}
